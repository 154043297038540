import React, { useState } from 'react';
import Loader from './Loader';

const ImageLoader = ({ src, alt }) => {
  const [loading, setLoading] = useState(true);

  const handleImageLoaded = () => {
    setLoading(false);
  };

  return (
    <div  className='flex items-center justify-center' style={{ width: '100%', height: '100%' }}>
      {loading && <Loader />}
      <img
        src={src}
        alt={alt}
        style={{ display: loading ? 'none' : 'block', width: '100%', height: '100%' }}
        className='object-contain w-full my-4'
        onLoad={handleImageLoaded}
      />
    </div>
  );
};

export default ImageLoader;
