import { Container } from '@mui/material';
import React from 'react'
import familyPic from '../assets/Images/Legacy/Family Pic.webp'
import krishnaRao from '../assets/Images/Legacy/Kishan_Rao_Myadam_300x300_.webp'
import raghuveer from '../assets/Images/Legacy/Raghuveer_Myadam_300x300_2.webp'
import ghantaGunta from '../assets/Images/Legacy/Ghanta_Chutta_300x300_3.webp';
import bambinoAgro from '../assets/Images/Legacy/Bambino Agro.webp';
import bambino from '../assets/Images/Legacy/Bambino.webp';
import amproBiscuits from '../assets/Images/Legacy/Ampro_Biscuits_300x300_4.webp';
import spectrum from '../assets/Images/Legacy/Spectrum_Power_Generation_Ltd._300x300.webp';
import hydrabadNursingHome from '../assets/Images/Legacy/Hydrabad Nursing Home.webp';
import ImageLoader from '../components/Loader/ImageLoader';


const legacyData = [
    {
        id: 1,
        img: krishnaRao,
        title: 'Kishan Rao Myadam',
        alt: 'Krishna rao maydam',
        desc1: 'Kishan Rao Myadam was born to Myadam Anjaiah and Sattamma in Hyderabad in 1936. Along with his brother, he played a pivotal role in establishing the Myadam family business.',
        desc2: 'In the 1950s, the family ventured into tobacco manufacturing, and Kishan Rao launched the iconic “Ghanta Chutta,” which became the most sought-after cigar in the market due to its high quality and robust supply chain.',
        belowDesc1: 'In the early 1970s, Kishan Rao expanded the family business and ventured into biscuit manufacturing with the launch of “Ampro” biscuits. Within a short period, the company emerged as the third-largest biscuit manufacturer in India, after Britannia and Parle. In 1973, Kishan Rao acquired the “Hyderabad Nursing Home,” the first corporate hospital in united Andhra Pradesh and now in Telengana.',
        belowDes2: 'While managing the family businesses successfully, Kishan Rao started “Bambino,” which soon became synonymous with vermicelli. He also promoted Spectrum Power Generation Ltd.',
        belowDesc3: 'Kishan Rao Myadam was an ambitious and hardworking entrepreneur who played a significant role in the growth and diversification of the Myadam family business.'
    },
    {
        id: 2,
        img: raghuveer,
        alt: 'Raghuveer Myadam',
        title: 'Raghuveer Myadam',
        desc1: "Raghuveer Myadam, born to Myadam Kishan Rao and Sugandha Bai in Hyderabad in 1960, was a selfless, humble, and visionary entrepreneur. He received his education from Hyderabad Public School and Badruka College of Commerce before joining his father's business at the age of 15.",
        desc2: 'Raghuveer is a co-founder of the Myadam family’s illustrious empire, and he played an integral role in its success.',
        belowDesc1: 'Raghuveer coined the name “Bambino” and built the facility for the factories. He was instrumental in steering the group companies to greater heights and played a vital role in acquiring the current brand value for the group. With his managerial prowess, he not only contributed to the financial closure of Spectrum Power Generation Ltd. but also ensured the smooth commissioning of the plant. He acquired a massive land bank for the entire family and group companies across various locations in India.',
        belowDes2: "Raghuveer was responsible for making Spectrum Power Generation Ltd. a pioneer in the country's power sector. He supervised finances, supply chain, marketing network, and human resources. He was a futuristic and successful businessman who loved learning and development. He was a member of the Hyderabad chapter of YPO.",
        belowDesc3: 'Raghuveer Myadam was a true leader, whose contributions were invaluable to the Myadam family business.'
    }
]


const listData = [
    {
        id: 1,
        img: ghantaGunta,
        alt: 'Ghanta Chutta Richmount ventures',
        title: 'Ghanta Chutta',
        description: 'Started by M. Kishan Rao and his brother M. Janardhan Rao, it was the ‘most popular brand’ among the customary smokers of yesteryears. It was among the top three tobacco brands in the cigar industry in United Andhra Pradesh in the late 1950s.'
    },
    {
        id: 2,
        img: amproBiscuits,
        alt: 'Ampro Biscuits Richmount ventures',
        title: 'Ampro Biscuits',
        description: 'It was the leading biscuit manufacturing company known for its glucose and cream biscuits. It was India’s third largest manufacturing company in terms of production after Britannia and Parle and owned and operated the single largest manufacturing plant in the 1970s.'
    },
    {
        id: 3,
        img: hydrabadNursingHome,
        alt: 'Hyderabad Nursing Home Richmount ventures',
        title: 'Hyderabad Nursing Home',
        description: 'Established in 1973, it is located at close quarters of the secretariat of Telangana. It is regarded as the first corporate hospital in the united Andhra Pradesh. It was a 150-bed hospital known for Gynecology, Urology and Orthopedics.'
    },
    {
        id: 4,
        img: spectrum,
        alt: 'Spectrum Power Generation Ltd.',
        title: 'Spectrum Power Generation Ltd.',
        description: 'After the Indian economy was liberalized in 1991, Spectrum Power Generation was one of the two companies that got the license to build a power plant. It was the first independent power plant to be set up in India on a land of over 700 acres in the early 90s, situated at Uppada near Kakinada in Andhra Pradesh. This project was executed without the guarantee of the Indian government. The installed capacity of the plant is 208 MW. It was a gas-fired plant which was set up in partnership with global majors like Rolls Royce, UK and Westinghouse, USA, and raised funds globally for the project. The project was of immense national importance then.'
    }
]

const familyBusinessData = [
    {
        id: 1,
        img: bambino,
        alt: 'Bambino Group Richmount Ventures',
        title: 'Bambino Group',
        desc1: 'The Bambino Group, which is the existing business of the Myadam family, operates primarily in the food processing space with a focus on the production of Pasta such as Vermicelli and Macaroni. The company has established over 6 manufacturing units across India.',
        desc2: "As an integrated company with its own flour mills, the Bambino Group has structured its various entities for smooth family functioning after the family realignment. Among these entities, the listed company is Bambino Agro Industries Ltd. Other entities under the Bambino Group include Bambino Pasta Food Industries Pvt. Ltd., Seshsayi Foods Pvt. Ltd., and Ghanta Foods Pvt. Ltd. This diverse portfolio of companies within the Bambino Group highlights the family's commitment to entrepreneurship and their success in creating a sustainable business empire."
    },
    {
        id: 2,
        img: bambinoAgro,
        alt: 'Bambino Agro Industries Ltd.',
        title: 'Bambino Agro Industries Ltd.',
        desc1: 'In 2016, the Bambino group underwent a realignment, resulting in Raghuveer Myadam and his family receiving the listed entity, Bambino Agro Industries Ltd.',
        desc2: 'Raghuveer Myadam served as Chairman and Managing Director from 14.09.2016 to 25.06.2017, while Kartekeya Myadam was an executive director from 11.11.2016 to 09.09.2017.',
        desc3: "Presently, Kartekeya Myadam has the largest ownership percentage in the company, with a stake of 37.91%.",
        desc4: "The company has a manufacturing unit in Gurugram Delhi NCR and has an exclusive distribution network of pasta and flour in the Northern and Eastern parts of India, defence canteens, and exports."
    }
]






const Legacy = () => {
    return (
        <Container>
            <ImageLoader src={familyPic} alt="Family of richmount ventures" />
            <div className='flex-col items-center justify-center my-5'>
                <h3 className='text-3xl font-medium text-center text-customBlue1'>Legacy</h3>
                <p className='mt-4 text-justify font-normal my-4 '>
                    The success of any legacy depends on its foundation, which sets the stage for future growth and prosperity. The present-day legacy of the Myadam family is a testament to the vision and hard work of its founder, Myadam Anjaiah. Anjaiah, the great-grandfather of Kartekeya Myadam, who was a successful trader and businessman in the 1930s and 40s. He laid the groundwork for a family business that would thrive for generations to come.
                </p>
            </div>
            {
                legacyData.map((item) => {
                    return (
                        <>
                            <div key={item.id} className='flex justify-start sm:max-md:flex-wrap sm:max-sm:flex gap-14 custom-what-we-do-container'>
                                <img className='object-contain sm:max-md:w-full w-72 my-4' src={item.img} alt={item.alt} />
                                <div className='flex-col'>
                                    <h2 className='text-2xl mt-5 text-customBlue font-medium sm:max-md:text-center sm:max-sm:text-center custom-text'>{item.title}</h2>
                                    <p className='mt-4 text-justify font-normal my-4 '>
                                        {item.desc1}
                                    </p>
                                    <p className='mt-4 text-justify font-normal my-4 '>
                                        {item.desc2}
                                    </p>
                                </div>
                            </div>
                            <div key={item.id} className='flex-col'>
                                <p className='mt-4 text-justify font-normal my-4 '>
                                    {item.belowDesc1}
                                </p>
                                <p className='mt-4 text-justify font-normal my-4 '>
                                    {item.belowDes2}
                                </p>
                                <p className='mt-4 text-justify font-normal my-4 '>
                                    {item.belowDesc3}
                                </p>
                            </div>
                        </>
                    )
                })
            }
            <Container className='mx-10 my-14'>

                {
                    listData.map((item) => {
                        return (
                            <div key={item.id} className='flex items-center sm:max-md:flex-wrap sm:max-sm:flex-wrap gap-14 my-10  custom-what-we-do-container'>
                                <img className='object-contain w-72 sm:max-md:w-full' src={item.img} alt={item.alt} />
                                <div>
                                    <h3 className='text-2xl text-customBlue1 font-medium mb-3 '>{item.title}</h3>
                                    <p className='text-justify font-normal'>{item.description}</p>
                                </div>
                            </div>
                        )

                    })
                }

            </Container>
            <h2 className='text-3xl font-medium text-center text-customBlue1'>Family Business</h2>
            <Container>

                {
                    familyBusinessData.map((item) => {
                        return (
                            <div key={item.id} className='flex items-center sm:max-md:flex-wrap sm:max-sm:flex-wrap gap-14 my-10  custom-what-we-do-container'>
                                <img className='object-contain w-96 sm:max-md:w-full' src={item.img} alt={item.alt} />
                                <div>
                                    <h3 className='text-2xl text-customBlue1 font-medium mb-3  '>{item.title}</h3>
                                    <p className='text-justify font-normal my-2'>{item.desc1}</p>
                                    {item.desc2 && <p className='text-justify font-normal my-2'>{item.desc2}</p>}
                                    {item.desc3 && <p className='text-justify font-normal my-2'>{item.desc3}</p>}
                                    {item.desc4 && <p className='text-justify font-normal mt-2'>{item.desc4}</p>}
                                </div>
                            </div>
                        )

                    })
                }
            </Container>
        </Container>
    )
}

export default Legacy;
