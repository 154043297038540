import { Container } from '@mui/material';
import React from 'react'
import philonthropy from '../assets/Images/Philanthropy/Philanthropy_704893369-Customized-2048x682.webp';
import ImageLoader from '../components/Loader/ImageLoader';


const Philanthropy = () => {
  return (
    <Container>
      <ImageLoader src={philonthropy} alt="Family of richmount ventures" />
      <div className='flex-col items-center justify-center my-5'>
        <h3 className='text-3xl font-medium text-center text-customBlue1'>Philanthropy</h3>
        <p className='mt-4 text-justify font-normal my-4 '>
          Philanthropy and sustainable investing are the cornerstones of Richmount Ventures.
        </p>
        <p className='mt-4 text-justify font-normal my-4 '>
          We are deeply committed to supporting mental health and well being initiatives and ventures supporting this cause as we strongly believe that thoughts, feelings and emotions are at the core of a person’s well being and happiness.
        </p>
        <p className='mt-4 text-justify font-normal my-4 '>
          Richmount Family Office's dedication to mental health and well being advocacy finds resonance in Swayam Foundation's impactful initiatives. Together, we champion a shared goal of democratizing access to mental and emotional health care. Swayam's focus on affordable therapy, gender-inclusive counseling, and caregiver support not only addresses critical gaps in mental  and emotional health services but also creates an avenue for societal transformation.
        </p>
        <p className='mt-4 text-justify font-normal my-4 '>
          By partnering with Swayam, Richmount Family Office is committed to championing mental and emotional health awareness, fostering communities that embrace empathy, and facilitating positive change in perceptions surrounding mental and emotional wellness.
        </p>
      </div>
    </Container>
  )
}

export default Philanthropy;

