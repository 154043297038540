import { Container } from '@mui/material';
import React, { useState } from 'react'
import contactHero from '../assets/Images/Contact us/Contact-us-scaled.webp';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import { MdLocationOn } from 'react-icons/md'; // Import the desired icon from react-icons
import L from 'leaflet';
import { renderToString } from 'react-dom/server'; // Import 'renderToString' from 'react-dom/server'
import ImageLoader from '../components/Loader/ImageLoader';
import axios from 'axios';

const ContactUs = () => {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        message: '',
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        // Here, you can handle the form submission data. For example, you can log it to the console.
        console.log(formData);

        try {
            let reqOptions = {
                url: `https://newweb.infosprint.com/new/form/richmount`,
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                data: JSON.stringify(formData),
            };

            let response = await axios.request(reqOptions);
            console.log('response: ', response);

            setFormData({
                name: '',
                email: '',
                message: ''
            })
            return response;
        } catch (error) {
            throw new Error('error', error)
        }


        // You can also send the data to your server using fetch or any other method.
    };
    return (
        <Container>
            <ImageLoader src={contactHero} alt='Contact Hero' />
            <div className="flex flex-col justify-between md:flex-row items-center gap-14 md:custom-what-we-do-container">
                <form onSubmit={handleSubmit} className="w-full md:w-full mt-4 p-4 border rounded-lg shadow-lg">
                    <div className="mb-4">
                        <label htmlFor="name" className="block text-gray-700 font-medium mb-2">Name</label>
                        <input
                            type="text"
                            name="name"
                            id="name"
                            value={formData.name}
                            onChange={handleChange}
                            placeholder="Name"
                            className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:ring focus:border-blue-300"
                            required
                        />
                    </div>
                    <div className="mb-4">
                        <label htmlFor="email" className="block text-gray-700 font-medium mb-2">Email</label>
                        <input
                            type="email"
                            name="email"
                            id="email"
                            value={formData.email}
                            onChange={handleChange}
                            placeholder="Email"
                            className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:ring focus:border-blue-300"
                            required
                        />
                    </div>
                    <div className="mb-4">
                        <label htmlFor="message" className="block text-gray-700 font-medium mb-2">Message</label>
                        <textarea
                            name="message"
                            id="message"
                            value={formData.message}
                            onChange={handleChange}
                            placeholder="Message"
                            rows="5"
                            className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:ring focus:border-blue-300"
                            required
                        />
                    </div>
                    <button
                        type="submit"
                        className="w-full py-2 mt-2 text-white bg-customBlue rounded-lg hover:bg-blue-700 focus:outline-none focus:ring focus:border-blue-300"
                    >
                        Submit
                    </button>
                </form>
                <LeafletMap />
            </div>

        </Container>
    )
}

export default ContactUs;



const LeafletMap = () => {
    const position = [17.42006380855073, 78.44901859420939]; // Hyderabad, India
    const customIcon = L.divIcon({
        html: renderToString(<MdLocationOn size={30} color="red" />),
        iconSize: [30, 30],
        className: 'custom-icon',
    });
    return (
        <MapContainer center={position} zoom={13} className='width-50percent custom-width-50percent' >
            <TileLayer
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            />
            <Marker position={position} icon={customIcon}>
                <Popup>
                    A marker indicating the default map center. <br />
                    Coordinates: {position[0]}, {position[1]}
                </Popup>
            </Marker>
        </MapContainer>
    );
};


