import React, { useEffect } from 'react';
import HeroImageBuilding from '../assets/Images/HomePage/Richmount-hero-image.webp';
import ChessHomePage from '../assets/Images/HomePage/Leadership-scaled-e1683257277861.webp';
import NatureImage from '../assets/Images/HomePage/RichMount-Website-Banner-03-e1676391505454-2048x583.webp';
import { Container } from '@mui/material';
import { NavLink } from 'react-router-dom';
import ImageLoader from '../components/Loader/ImageLoader';
import MediaImage from '../assets/Images/Media/media-image.png'

const customNewClass = 'p-4 my-4 text-white flex-none justify-center bg-customBlue rounded-full border-2 shadow-md hover:border-gray border-slate'

const Home = () => {

  return (
    <div>
      <Container>
        <ImageLoader src={HeroImageBuilding} alt="Hero Building" />
        <h2 className='text-3xl mt-4 text-center text-customBlue1 font-medium sm:text-4xl'>
          Richmount Ventures Family Office
        </h2>
        <div className='flex-col items-center justify-center my-10'>
          <h3 className='text-3xl font-medium text-center text-customBlue1'>Our Story</h3>
          <p className='mt-4 text-justify font-normal my-4 '>
            Richmount Ventures is a single-family office that was established in 2018 by Kartekeya Myadam,
            its founder and Chief Investment Officer.
            The office's primary objective is to manage the
            family's wealth in a comprehensive manner, while
            also laying out a roadmap for future generations. To achieve this, Richmount Ventures works with a dedicated investment advisory team and globally renowned wealth managers to identify and evaluate investment opportunities in public markets, private markets, global markets, and real estate.
          </p>
          <div className="flex  justify-center"> {/* Add this div as a flex container */}
            <NavLink
              to='/about'
              className={customNewClass}
              style={{
                color: 'white',
                textAlign: 'center',
              }}
            >
              Read More
            </NavLink>
          </div>
        </div>
        <div className='flex-col items-center justify-center my-10'>
          <h3 className='text-3xl font-medium text-center text-customBlue1'>What We Do</h3>
          <p className='mt-4 text-justify font-normal my-4 '>
            The family office focuses on identifying investment opportunities and explore new investment models, research emerging trends and markets, and identifying new opportunities to create a diversified portfolio that is rooted in sound investment principles and aligned with our long-term objectives.        </p>
          <div className="flex  justify-center"> {/* Add this div as a flex container */}
            <NavLink
              to='/what-we-do'
              className={customNewClass}
            >
              Read More
            </NavLink>
          </div>
        </div>
        <div className='flex-col items-center justify-center my-10'>
          <h3 className='text-3xl font-medium text-center text-customBlue1'>Leadership</h3>
          <p className='mt-4 text-justify font-normal my-4 '>
            Kartekeya Myadam is the founder and Chief Investment Officer, Richmount Ventures. He is responsible for creating investment strategies, capital allocation and performance review while managing risk across portfolios with the objective of growing and preserving the family wealth. He is also responsible for establishing processes to ensure investment policies are followed in letter and spirit.
          </p>
          <div className="flex  justify-center"> {/* Add this div as a flex container */}
            <NavLink
              to='/leadership'
              className={customNewClass}
              style={{
                color: 'white',
                textAlign: 'center',
              }}
            >
              Read More
            </NavLink>
          </div>
        </div>
        <ImageLoader src={ChessHomePage} alt="Chess richmount ventures" />
        <div className='flex-col items-center justify-center my-10'>
          <h3 className='text-3xl font-medium text-center text-customBlue1'>Legacy</h3>
          <p className='mt-4 text-justify font-normal my-6'>
            The success of any legacy depends on its foundation, which sets the stage for future growth and prosperity. The present-day legacy of the Myadam family is a testament to the vision and hard work of its founder, Myadam  Anjaiah. Anjaiah, the great-grandfather of Kartekeya Myadam, who was a successful trader and businessman in the 1930s and 40s. He laid the groundwork for a family business that would thrive for generations to come.          </p>
          <div className="flex  justify-center"> {/* Add this div as a flex container */}
            <NavLink
              to='/legacy'
              className={customNewClass}
              style={{
                color: 'white',
                textAlign: 'center',
              }}
            >
              Read More
            </NavLink>
          </div>
        </div>
        <div className='flex-col items-center justify-center my-10'>
          <h3 className='text-3xl font-medium text-center text-customBlue1'>Philanthropy</h3>
          <ImageLoader src={NatureImage} alt="Nature Richmount ventures" />
          <p className='mt-4 text-justify font-normal my-6'>
            Philanthropy and sustainable investing are the cornerstones of Richmount Ventures.
            We are deeply committed to supporting mental health and well being initiatives and ventures supporting this cause as we strongly believe that thoughts, feelings and emotions are at the core of a person's well being and happiness.
          </p>
          <div className="flex  justify-center"> {/* Add this div as a flex container */}
            <NavLink
              to='/philanthropy'
              className={customNewClass}
              style={{
                color: 'white',
                textAlign: 'center',
              }}
            >
              Read More
            </NavLink>
          </div>
        </div>
        <div className='flex-col items-center justify-center my-10'>
          <ImageLoader src={MediaImage} alt="Media Richmount ventures" />
          <h3 className='text-3xl font-medium text-center text-customBlue1'>Media</h3>
          <p className='mt-4 text-justify font-normal my-4 '>
            Delve into a world of insightful interviews and features spotlighting leadership in media, events, and current industry updates.
          </p>
          <div className="flex  justify-center"> {/* Add this div as a flex container */}
            <NavLink
              to='/media'
              className={customNewClass}
              style={{
                color: 'white',
                textAlign: 'center',
              }}
            >
              Read More
            </NavLink>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default Home;
