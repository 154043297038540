import { Container } from '@mui/material';
import React from 'react'
import chessImgForLederShip from '../assets/Images/Leadership/Leadership-scaled-e1683257277861.webp'
import kartekeya from '../assets/Images/Leadership/Kartekeya_Myadam_Banner_1000x400_4-2048x819.webp'
import ImageLoader from '../components/Loader/ImageLoader';

const Leadership = () => {
    return (
        <Container>
            <ImageLoader src={chessImgForLederShip} alt="chessImgForLederShip" />
            <div className='flex-col items-center justify-center my-5'>
                <h3 className='text-3xl font-medium text-center text-customBlue1 '>Kartekeya Myadam</h3>
                <h4 className='text-2xl font-normal text-center my-6'>Founder and Chief Investment Officer</h4>
                <p className='mt-4 text-justify font-normal my-4 '>
                    Kartekeya Myadam is the founder and Chief Investment Officer, Richmount Ventures. He is responsible for creating investment strategies, capital allocation and performance review while managing risk across portfolios with the objective of growing and preserving the family wealth. He is also responsible for establishing processes to ensure investment policies are followed in letter and spirit.
                </p>
                <p className='mt-4 text-justify font-normal my-4 '>
                    Kartekeya is a third - generation entrepreneur, who joined the family business, Bambino Agro Industries Limited at an early age of 18 and gained his initial experience in business by working closely with his late father Mr.Raghuveer Myadam and late grand father Mr.M.Kishan Rao while completing his education.Under their guidance he slowly started to lead new initiatives in the company and was elevated as an Executive Director of the company at the age of 25. Currently he is the largest shareholder of Bambino Agro Industries Limited.
                </p>
            </div>
            <ImageLoader src={kartekeya} alt="kartekeya" />
            <p className='mt-4 text-justify font-normal my-4 '>
                He believes in continuous learning and has been closely connected with think tanks in the investment world. He keeps himself abreast of the new and evolving principles of entrepreneurship and leadership which in turn helps him apply those learnings in Richmount Ventures’ long term growth vision and management.
            </p>
            <p className='mt-4 text-justify font-normal my-4 '>
                Kartekeya is a Masters in International Management from the University of Reading, UK.He has attended several short term management and development programs, courses and seminars in Harvard University, University of California at Berkeley, USA, Richmond; the American International University in London, Columbia University, USA.IIM Ahmedabad and ISB Hyderabad.
            </p>
            <p className='mt-4 text-justify font-normal my-4 '>
                He is a member of various esteemed global communities specific to investments and family offices, and has spoken at various business and industry forums.
            </p>
        </Container>
    )
}

export default Leadership;







