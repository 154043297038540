import { Container } from '@mui/material';
import React from 'react'
import investmentImage from '../assets/Images/What we do/Homepage-Investment-scaled.webp'
import groupInvestment from '../assets/Images/What we do/Investment-Advisory-Board-500x344.webp'
import investmentAnimation from '../assets/Images/What we do/Investment_ideology_process-removebg-preview.webp'
import wealthReportingImage from '../assets/Images/What we do/Wealth-Reporting-768x533.webp'
import wealthStrategyImage from '../assets/Images/What we do/Wealth-Strategy-1-768x516.webp'
import governance from '../assets/Images/What we do/Governance.webp'
import taxAndPractice from '../assets/Images/What we do/Tax-and-Accounting-Practice-768x522.webp'
import lifeGoals from '../assets/Images/What we do/Life-Goals-768x518.webp'
import ImageLoader from '../components/Loader/ImageLoader';


const data = [
    {
        id: 1,
        img: wealthStrategyImage,
        title: 'Wealth strategy',
        description: 'Develop investment objectives that include drafting of investment policy statements and guidelines, risk tolerance matrix and creation of an appropriate target asset allocation plan.',
        alt: 'wealth strategy image richmount ventures'
    },
    {
        id: 2,
        img: wealthReportingImage,
        title: 'Wealth reporting',
        description: "Prepare a robust reporting frameworks that gives a bird's eye view of the balanced, risk-adjusted investment portfolio. This aids in taking data-driven investment decisions keeping in mind the family-office's long-term objectives.",
        alt: 'wealth report image richmount ventures'
    },

    {
        id: 3,
        img: groupInvestment,
        title: 'Investment advisory team',
        description: "Our dedicated investment advisory team comprising professionals and subject matter experts provides guidance and support to ensure the success of our family office's investment   strategy",
        alt: 'group investment image richmount ventures'
    },
    {
        id: 4,
        img: governance,
        title: 'Governance',
        description: "Strong policies that ensure strict adherence to the agreed investment principles, ensuring transparency between the family-office's principals and the operating team.",
        alt: 'governance image richmount ventures'
    },
    {
        id: 5,
        img: taxAndPractice,
        title: 'Tax and accounting practices',
        description: 'Establish a strong tax and accounting framework that evaluates multiple post-tax scenarios for each asset class within the portfolio, and recommends the most cost-effective options.',
        alt: 'tax and price image richmount ventures'
    },
    {
        id: 6,
        img: lifeGoals,
        title: 'Life goals',
        description: 'Clearly articulated and well-defined policies to fulfill personal wealth-utilization aspirations and dreams. Our investment strategies prioritize such personal goals, ensuring they receive appropriate consideration and attention.',
        alt: 'life goals image richmount ventures'
    }
]

const investmentProcess = [
    {
        id: 1,
        title: 'Independent Wealth Analysis',
        description: 'Make decisions and fine tune on the basis of independent and objective analysis.'
    },
    {
        id: 2,
        title: 'Implication Analysis',
        description: 'Ensure all tax implications and any other implication on reputation, ownership structure, nominations, etc. are adequately addressed before investing.'
    }
]

const investmentProcess2 = [
    {
        id: 3,
        title: 'Risk Overview',
        description: 'Robust risk assessment and asset protection frameworks to protect any downside.',
        description2: 'Complete risk assessment for evaluating and verifying the quality of investments.'
    },
    {
        id: 4,
        title: 'Asset Consolidation',
        description: "Have a bird's eye view of the family's assets with personalized reporting and dashboards.",
        description2: 'Regular valuation of the entire portfolio across accounts held with numerous financial intermediaries.'
    }
]

const WhatWeDo = () => {
    return (
        <Container>
            <div className='my-4'>
                <ImageLoader src={investmentImage} alt="Investment richmount ventures" />
                <p className='mt-4 text-justify font-normal my-4 '>
                    The family office focuses on identifying investment opportunities and explore new investment models, research emerging trends and markets, and identifying new opportunities to create a diversified portfolio that is rooted in sound investment principles and aligned with our long-term objectives.
                </p>
            </div>
            <Container className='mx-10 my-14'>
                {
                    data.map((item) => {
                        return (
                            <>
                                <div key={item.id} className='flex sm:max-md:flex-wrap sm:max-sm:flex-wrap gap-14 my-10 custom-what-we-do-container'>
                                    <img className='object-contain w-72 sm:max-md:w-full' src={item.img} alt={item.alt} />
                                    <div>
                                        <h3 className='text-2xl text-customBlue1 font-medium mb-3 '>{item.title}</h3>
                                        <p className='text-justify font-normal'>{item.description}</p>
                                    </div>
                                </div >
                            </>
                        )
                    })
                }
            </Container>

            <div>
                <h2 className='text-2xl font-medium text-customBlue1 text-center my-10'>Investment Process</h2>
                <div className='inline-flex sm:max-md:flex-col sm:max-sm:flex-col custom-what-we-do-container gap-10'>
                    {investmentProcess.map((item) => (

                        <div key={item.id} className='w-full md:w-1/2 mx-3 mb-8'>
                            <h3 className='text-xl text-center font-medium text-customBlue1'>{item.title}</h3>
                            <p className='mt-4 text-justify font-normal my-4'>{item.description}</p>
                            {item?.description2 && (
                                <p className='mt-4 text-justify font-normal my-4'>{item.description2}</p>
                            )}
                        </div>

                    ))}
                </div>
                <div className='inline-flex sm:max-md:flex-col sm:max-sm:flex-col custom-what-we-do-container gap-10'>
                    {investmentProcess2.map((item) => (
                        <div key={item.id} className='w-full md:w-1/2 mx-3 mb-8'>
                            <h3 className='text-xl text-center font-medium text-customBlue1'>{item.title}</h3>
                            <p className='mt-4 text-justify font-normal my-4'>{item.description}</p>
                            {item.description2 && (
                                <p className='mt-4 text-justify font-normal my-4'>{item.description2}</p>
                            )}
                        </div>
                    ))}
                </div>
            </div>
            <img className='object-contain width-fit my-4 mx-auto' src={investmentAnimation} alt="Investment Animation richmount ventures" />
            <div className='inline-flex sm:max-md:flex sm:max-sm:flex-col custom-what-we-do-container gap-10 my-10'>
                <div className='w-full md:w-1/2 mx-3 mb-8'>
                    <h3 className='text-xl text-center font-medium text-customBlue1'>Cash flow budgeting</h3>
                    <p className='mt-4 text-justify font-normal my-4'>Cash flow projections and management of the same.</p>
                </div>
                <div className='w-full md:w-1/2 mx-3 mb-8'>
                    <h3 className='text-xl text-center font-medium text-customBlue1'>Performance Analysis</h3>
                    <p className='mt-4 text-justify font-normal my-4'>Detailed performance report by aggregating various clusters (analysis by asset class, security, business sector, country etc.). </p>
                </div>
                <div className='w-full md:w-1/2 mx-3 mb-8'>
                    <h3 className='text-xl text-center font-medium text-customBlue1'>Management Cost Analysis</h3>
                    <p className='mt-4 text-justify font-normal my-4'>Independently analyze banking fees and taxes on assets to have a full and accurate picture of the investments portfolio.</p>
                </div>
            </div>
        </Container >
    )
}

export default WhatWeDo;


// Independent wealth analysis
// Make decisions and fine tune on the basis of independent and objective analysis.
//  
// Implication analysis
// Ensure all tax implications and any other implication on reputation, ownership structure, nominations, etc. are
// adequately addressed before investing.
//  
// Risk overview
// Robust risk assessment and asset protection frameworks to protect any downside.
// Complete risk assessment for evaluating and verifying the quality of investments. 
//  
// Cash flow budgeting
// Cash flow projections and management to ensure appropriate liquidity management to be able to invest in
// opportunistic growth opportunities as well.
// Asset consolidation
// Have a bird&#39;s eye view of assets with personalized reporting and dashboards. Also conduct regular valuation of the
// portfolio across accounts held with numerous financial intermediaries. 
// Performance analysis
// Detailed performance report by aggregating various data clusters (analysis by asset class, security, business sector,
// country etc.). 
//  
// Management cost analysis
// Independently analyze banking fees and taxes on assets to have a full and accurate picture of the investments
// portfolio.