import React, { useEffect, useState } from 'react'
import Navbar from '../components/Navbar/NavBar'
import Footer from '../components/Footer/Footer'
import { Outlet } from 'react-router-dom'
import { getTodaysVisits, getTotalVisits, getUniqueVisitors, recordVisit } from '../api/agent';

const checkAndRecordVisit = async () => {
  const today = new Date().toISOString().split('T')[0]; // Get current date in YYYY-MM-DD format
  const lastVisitDate = localStorage.getItem('lastVisitDate');

  if (lastVisitDate !== today) {
    try {
      // Replace 'your_ip_address' with actual logic to get the user's IP address if necessary
      await recordVisit();
      localStorage.setItem('lastVisitDate', today);
    } catch (error) {
      console.error('Error recording visit:', error);
    }
  }
};

const RootLayout = () => {


  const [visits, setVisits] = useState({
    totalVisit: 0,
    todayVisit: 0,
    uniqueVisit: 0
  })

  useEffect(() => {
    checkAndRecordVisit();
  }, []);


  const fetchData = async () => {
    const totalVisits = await getTotalVisits();
    const todaysVisits = await getTodaysVisits();
    const uniqueVisitors = await getUniqueVisitors();

    setVisits({
      todayVisit: todaysVisits.data,
      totalVisit: totalVisits.data,
      uniqueVisit: uniqueVisitors.data
    })
  };

  useEffect(() => {
    fetchData()
  }, [])


  return (
    <div >
      <Navbar />
      <main className='mt-2'>
        <Outlet />
      </main>
      <Footer todayVisit={visits.todayVisit} totalVisits={visits.totalVisit} uniqueVisit={visits.uniqueVisit}  />
    </div>
  )
}

export default RootLayout
