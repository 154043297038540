import React from "react";
import SimpleSlider from "../components/SimpleSlider";

import SliderWithContent from "../components/SliderWithContent";
// import hero from "../assets/Images/HomePage/Richmount-hero-image.webp";
import p1slide1 from "../assets/Images/SliderImages/Slide1.png";
import p1slide2 from "../assets/Images/SliderImages/Slide2.png";
import p1slide3 from "../assets/Images/SliderImages/Slide3.png";

import p2slide4 from '../assets/Images/SliderImages/Slide4.png';
import p2slide5 from '../assets/Images/SliderImages/Slide5.png'
import p2slide1 from '../assets/Images/SliderImages/5.png';
import p2slide2 from '../assets/Images/SliderImages/4.png';
import ImageWithContent from "../components/ImageWithContent";
import p4slide1 from "../assets/Images/SliderImages/p4slide-1.png"
import p4slide2 from '../assets/Images/SliderImages/p4slide-2.png'
import p4slide3 from '../assets/Images/SliderImages/p4slide-3.png'
import p4slide4 from '../assets/Images/SliderImages/p4slide-4.png'

import kartekeya from "../assets/Images/Leadership/Kartekeya_Myadam_Banner_1000x400_4-2048x819.webp";
import { ImportContactsRounded } from "@mui/icons-material";



const mediadata = [
  {
    id: 1,
    slider: {
      img1: p1slide2,
      img2: p1slide1,
      img3: p1slide3,
    },
    header: "South India Media Summit 2023",
    subHeader: "",
    para1: "Kartekeya Myadam Shares Insights at South India Media Summit  ",


    para2:
      "Kartekeya Myadam, Founder and CIO of Richmount Ventures Family Office, was a featured speaker at the prestigious South India Media Summit. He joined prominent thought leaders in thoughtful discussions about innovations shaping the digital landscape.",

    para3:
      "Leveraging his expertise from the financial sector, Mr. Myadam provided unique perspectives on emerging trends across industries. He engaged fellow panelists and attendees with insightful commentary on the technologies, strategies, and innovations that will define the future.",
  },
  {
    id: 2,
    slider: {
      img1: p2slide4,
      img2: p2slide5,
      img3: p2slide1,
      img4: p2slide2,
    },
    header: "CEO Insights",
    subHeader: "",
    para1:
      "Delighted to share my feature among industry luminaries in CEO Insights Magazine, spotlighting Richmount Ventures Family Office's unwavering dedication to unmatched excellence in wealth management and its pursuit of elevated standards in preserving and managing wealth.      This achievement stands as a testament to the collaborative efforts of the Richmount Ventures Family Office team, alongside our valued associates and partners, who have played integral roles in our journey of growth and success.  ",
    para2: "",
    para3: "",
    anchor: true,
    anchorTitle: "Source : Chief Investment Officers - November - 2023 - CEOInsights India Magazine",
    anchorLink: "https://www.ceoinsightsindia.com/magazines/chief-investment-officers-november-2023/#page=30"
  },
  {
    id: 3,
    slider: {
      img1: p4slide1,
    },
    header: 'INNERREVIEW',
    subHeader: "Mr. Kartekeya Myadam has got featured in the INNERREVIEW Magazine",
    anchor: true,
    anchorTitle: "Source : Innerreview",
    anchorLink: "https://theinnerreview.com/mag/the-10-most-young-dynamic-business-leaders-to-follow-kartekeya-myadam"

  },

];

const imageWithContentData = [
  {
    id: 1,
    img: kartekeya,
    header: "Campden",
    subHeader: "Guiding principles of commitment, transparency and forward-thinking",
    para1: "In an exclusive interview by Campden Wealth, Kartekeya Myadam, the third generation founder and Chief Investment Officer of Richmount Ventures Family Office, talks about the origins of the family wealth and his vision for the private family office.",
    anchor: true,
    anchorTitle: "Source : CampdenFB",
    anchorLink: "https://www.campdenfb.com/article/kartekeya-myadam-interview-the-guiding-principles-of-commitment-transparency-and-forward-thinking"


  }
]

const Media = () => {
  return (
    <div>

      {/* 1st para exchange */}
      <SliderWithContent
        slider={mediadata[0].slider}
        header={mediadata[0].header}
        subHeader={mediadata[0].subHeader}
        para1={mediadata[0].para1}
        para2={mediadata[0].para2}
        para3={mediadata[0].para3}
      />

      {/* 2nd add 2 images in front */}
      <SliderWithContent
        slider={mediadata[1].slider}
        header={mediadata[1].header}
        subHeader={mediadata[1].subHeader}
        para1={mediadata[1].para1}
        para2={mediadata[1].para2}
        para3={mediadata[1].para3}
        anchor={mediadata[1].anchor}
        anchorTitle={mediadata[1].anchorTitle}
        anchorLink={mediadata[1].anchorLink}
      />
      {/* 3rd image with content */}
      <ImageWithContent
        img={imageWithContentData[0].img}
        header={imageWithContentData[0].header}
        subHeader={imageWithContentData[0].subHeader}
        para1={imageWithContentData[0].para1}
        anchor={imageWithContentData[0].anchor}
        anchorTitle={imageWithContentData[0].anchorTitle}
        anchorLink={imageWithContentData[0].anchorLink}

      />

      {/* //  4th images added slider */}
      <ImageWithContent
        slider={mediadata[2].slider}
        img={mediadata[2].slider.img1}
        header={mediadata[2].header}
        subHeader={mediadata[2].subHeader}
        anchor={mediadata[2].anchor}
        anchorTitle={mediadata[2].anchorTitle}
        anchorLink={mediadata[2].anchorLink}
      />
    </div>
  );
};

export default Media;
